"use strict";
const jsonLoadModules = '/typo3conf/ext/ndstemplate/Resources/Public/Assets/Js/compiled.jsModules.json';
let loadedModules = [];

function loadModule(module) {
  if (loadedModules.includes(module.name)) {
    return;
  }
  if (module.type === 'element' && module.selector) {
    const element = document.querySelector(module.selector);
    if (element !== null) {
      if(module.mode === 'intersect') {
        return loadScriptOnIntersect(module.path, module.name, true, element);
      } else {
        return loadScript(module.path, module.name, true);
      }
    }
  }
  if (module.type === 'always') {
    const isModule = (module.isModule) ? true : false;
    loadScript(module.path, module.name, isModule);
  }
}

function loadJSON(callback, jsonPath) {
  let xobj = new XMLHttpRequest();
  xobj.overrideMimeType('application/json');
  xobj.open('GET', jsonPath, true);
  xobj.onreadystatechange = function () {
    if (xobj.readyState === 4 && xobj.status === 200) {
      callback(xobj.responseText);
    }
  };
  xobj.send('');
}

function loadScriptOnIntersect(scriptPath, moduleName, isModule, target) {
  if(typeof window.IntersectionObserver !== 'undefined') {
    let observer = new IntersectionObserver(function(entries) {
      let isIntersecting = false;
      entries.forEach(function(entry) {
        isIntersecting = entry.isIntersecting || isIntersecting;
      });
      if(isIntersecting) {
        loadScript(scriptPath, moduleName, isModule);
        observer.disconnect();
      }
    }, {});
    observer.observe(target);
  } else {
    loadScript(scriptPath, moduleName, isModule);
  }
}

function loadScript(scriptPath, moduleName, isModule) {
  let scriptModule = document.createElement('script');
  const pathPrefix = (scriptPath.substring(0,1) === '/') ? '' : '/';
  if (document.querySelector('script[src="' + pathPrefix + scriptPath + '"]')) {
    // we dont want to load the same module multiple times
    return;
  }
  scriptModule.setAttribute('src', pathPrefix + scriptPath);
  if (isModule) {
    scriptModule.setAttribute('type', 'module');
    scriptModule.setAttribute('async', '');
    // add crossorigin attribute for DEV
    const devUrlRegEx = '[a-zA-Z0-9-_\.]+\.neusta-ds\.de';
    if (window.location.hostname.match(devUrlRegEx)) {
      scriptModule.setAttribute('crossorigin', '');
    }
    loadedModules.push(moduleName);
  }
  document.body.appendChild(scriptModule);
}

function initModuleLoader() {
  loadJSON(function (response) {
    const modules = JSON.parse(response);
    modules.forEach(function (module) {
      loadModule(module);
    });
  }, jsonLoadModules);
}

initModuleLoader();
